import axios from "axios";

const fetchTicketData = async (bookingNumber) => {
  try {
    const { data } = await axios.post(
      `https://budiman.io/api/v2/search-ticket/cetak-tiket/invoice`,
      {
        invoice: bookingNumber,
      }
    );
    console.log(data.list_ticket);
    return data.list_ticket;
  } catch (error) {
    return [];
  }
};

export { fetchTicketData };
